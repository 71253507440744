<template>
  <base-material-card :title="$t('more')" id="more" fluid tag="section">
    <v-row class="mx-1" justify="center">
      <v-col cols="4" v-for="(item, i) in computedItems" :key="i">
        <v-card class="text-center my-1" @click="$router.push(item.to)">
          <v-list>
            <v-icon class="ma-5" size="40">{{ item.icon }}</v-icon>
            <p class="primary--text mb-0 text-caption">{{ $t(item.title) }}</p>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <hr class="my-4" />
    <v-row align="center" justify="space-around">
      <v-btn tile outlined @click.native="logout">
        <v-icon left>
          mdi-power
        </v-icon>
        Desconectar
      </v-btn>
    </v-row>
    <v-row justify="center" class="mt-2">
      <div class="caption mx-2 gris1--text">v{{ pjson.version }}</div>
    </v-row>
  </base-material-card>
</template>

<script>
export default {
  name: "More",
  mounted() {
    this.pjson = require("@/../package.json");
  },
  data() {
    return {
      pjson: {},
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({
          name: "Login",
        });
      });
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
  computed: {
    itemsByRole() {
      let array = [];
      switch (this.$store.state.auth.user.role.id) {
        case 1:
          //ADMIN
          array.push({
            icon: "$files",
            title: this.$tc("files", 2),
            to: "/files",
          });
          array.push({
            icon: "$studio",
            title: this.$tc("studio", 2),
            to: "/studios",
          });
          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });
          array.push({
            icon: "mdi-account-multiple-outline",
            title: this.$tc("user", 2),
            to: "/users",
          });

          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });

          break;
        case 2:
        case 7:
          //TATTOER
          array.push({
            icon: "$files",
            title: this.$tc("files", 2),
            to: "/files",
          });
          array.push({
            icon: "$studio",
            title: this.$tc("studio", 2),
            to: "/studios",
          });
          array.push({
            icon: "$appointments",
            title: this.$tc("appointment", 2),
            to: "/appointment",
            count: this.$store.getters[
              "appointments/inscriptionAppointmentsCount"
            ],
          });
          if (this.$store.state.auth.box_active) {
            array.push({
              icon: "$boxes",
              title: this.$tc("boxes.boxs", 2),
              to: "/box",
            });
          }
          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });
          if (this.$store.getters["auth/getPlan"]("inventory"))
            array.push({
              icon: "$inventory",
              title: this.$tc("inventory", 2),
              to: "/inventory",
            });
          array.push({
            icon: "$economy",
            title: this.$tc("economy", 2),
            to: "/economy",
          });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });
          break;
        case 8:
        case 9:
          array.push({
            icon: "$appointments",
            title: this.$tc("appointment", 2),
            to: "/appointment",
            count: this.$store.getters[
              "appointments/inscriptionAppointmentsCount"
            ],
          });

          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });
          break;

        case 3:
        case 5:
          //STUDIO
          array.push({
            icon: "$files",
            title: this.$tc("files", 2),
            to: "/files",
          });

          if (
            this.$store.getters["auth/getSetting"]("box_active") == 1 &&
            this.$store.getters["auth/getPlan"]("boxes")
          ) {
            array.push({
              icon: "$boxes",
              title: this.$tc("boxes.boxs", 2),
              to: "/box",
            });
          }

          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });

        // array.push({
        //   icon: "mdi-chart-sankey",
        //   title: this.$tc("stat", 2),
        //   to: "/stats",
        // });

        case 6:
          if (this.$store.getters["auth/getPlan"]("inventory"))
            array.push({
              icon: "$inventory",
              title: this.$tc("inventory", 2),
              to: "/inventory",
            });
          if (this.$store.getters["auth/getPlan"]("economy"))
            array.push({
              icon: "$economy",
              title: this.$tc("economy", 2),
              to: "/economy",
            });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });
          break;
        case 4:
          break;
      }

      /*
      array.push({
        icon: "$economy",
        title: this.$tc("invoices", 2),
        to: "/invoices",
      });
      */

      return array;
    },
    computedItems() {
      return this.itemsByRole.map(this.mapItem);
    },
  },
};
</script>

<style lang="sass">
#more
  #border-card
    .v-card__title
      font-size: 10px !important
      white-space: nowrap !important
  .v-icon
    color: var(--v-primary-base) !important
  hr
    width: 80%
    margin-left: 10% !important
    margin-right: 10% !important
    height: 1px
    background-color: var(--v-primary-base) !important
    border: none
</style>
